<template>
    <div class="goods-info-content">
        <div class="add-goods">
            <el-input placeholder="请输入内容" v-model="searchKeyword" style="width: 500px" class="searchInput" @keyup.enter.native="changeSearchKeyword">
                <el-button slot="append" icon="el-icon-search" @click="changeSearchKeyword"></el-button>
            </el-input>
        </div>
        <el-table :data="hotKeywordList" class="customTable" style="width: 100%; flex: 1; margin-top: 20px" height="1%">
            <el-table-column prop="name" label="关键词"></el-table-column>
            <el-table-column prop="search_index" label="搜索指数" align="center" sortable
                             :sort-method="(...args) => search_method('search_index', ...args)">
            </el-table-column>
            <el-table-column prop="search_click_index" label="搜索点击指数" align="center" sortable
                             :sort-method="(...args) => search_method('search_click_index', ...args)">
            </el-table-column>
            <el-table-column prop="click_rate" label="点击率" align="center" sortable
                             :sort-method="(...args) => search_method('click_rate', ...args)">
            </el-table-column>
            <el-table-column prop="turnover_index" label="成交金额指数" align="center" sortable
                             :sort-method="(...args) => search_method('turnover_index', ...args)">
            </el-table-column>
            <el-table-column prop="turnover_conversion_rate" label="成交转化率" align="center" sortable
                             :sort-method="(...args) => search_method('turnover_conversion_rate', ...args)">
            </el-table-column>
        </el-table>
        <el-pagination class="pages-center" style="margin: 40px 0"
                       :current-page="hotKeywordPages.currentPageNum"
                       :page-size="hotKeywordPages.eachPageNum"
                       layout="prev, pager, next, jumper"
                       :total="hotKeywordPages.total"
                       @current-change="hotKeywordPagesCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import { examStuExamKeyword, adminKeyWordGetKeyWord } from '@/utils/apis.js'
    export default {
        name: "HotKeyword",
        data() {
            return {
                //关键词列表
                hotKeywordList: [],
                //搜索关键词
                searchKeyword: '',
                //分页
                hotKeywordPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
            }
        },
        mounted() {
          this.getKeywordsList();
        },
        methods: {
            //获取热门关键词列表
            getKeywordsList() {
                // let param = {
                //     page: '1',
                //     paging: this.hotKeywordPages.currentPageNum,
                //     pageSize: this.hotKeywordPages.eachPageNum
                // }
                // param.exam_module_id = this.$route.query.exam_module_id ? parseInt(this.$route.query.exam_module_id) : parseInt(localStorage.getItem('exam_module_id'));
                // if (this.searchKeyword) {
                //     param.keyword = this.searchKeyword;
                // }
                // if (this.$route.query.id) {
                //     param.shop_management = this.$route.query.id
                // }
                let param = {
                    id: this.$route.query.id
                }
                if (this.$route.query.id) {
                    param.id = this.$route.query.id
                }
                adminKeyWordGetKeyWord(param).then((res) => {
                    for (let x = 0; x < res.data.content.length; x ++) {
                        res.data.content[x].turnover_index = Number(res.data.content[x].turnover_index);
                    }
                    this.hotKeywordList = res.data.content;
                }, (err) => {
                    console.log(err);
                })
            },
            //分页
            hotKeywordPagesCurrentChange(val) {
                this.hotKeywordPages.currentPageNum = val;
            },
            //搜索关键词
            changeSearchKeyword() {
                this.hotKeywordPages.currentPageNum = 1;
                this.getKeywordsList();
            },
            // 搜索指数排序
            search_method(prop, a, b) {
              return a[prop] != b[prop] ? a[prop] - b[prop] : b.id - a.id;
            },

        }
    }
</script>

<style scoped lang="scss">
    .goods-info-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 40px 200px;
        .add-goods {
            margin-top: 20px;
            .searchInput {
                ::v-deep .el-input-group__append {
                    padding: 0 8px;
                    background: #2DC079;
                    .el-button {
                        i {
                            color: #fff;
                            font-size: 16px;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
        .customTable {
            .goods-info {
                display: flex;
                align-items: center;
                img {
                    width: 80px;
                    height: 80px;
                    margin-right: 10px;
                }
                .goods-name {
                    flex: 1;
                    width: 1%;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    font-size: 16px;
                }
            }
            .a-link {
                font-size: 16px;
            }
            ::v-deep {
                .el-table__empty-block {
                    background-image: url("../../../../assets/images/student/goods-null-data.png");
                    background-repeat: no-repeat;
                    background-position: center;
                    .el-table__empty-text {
                        margin-top: 150px;
                    }
                }
            }
        }
    }
</style>
